import { getEnv } from "./utils";

const USER = "user";
const SECOND = 1000;
const MINUTE = SECOND * 60;
const TOKEN_TTL = MINUTE * 19;
const IDLE_TIMEOUT_MIN = MINUTE * 10;
const PASSWORD_STRENGTH = {
  MIN_LENGTH: 8,
  MIN_UPPERCASE: 1,
  MIN_LOWERCASE: 1,
  MIN_SPECIALCHARACTER: 1,
  MIN_NUMERIC: 1,
};
const EMAIL_PLACEHOLDER = "Enter an email address";
const VERIFICATION_CODE_PLACEHOLDER = "123456";
const PASSWORD_PLACEHOLDER = "********";
const FIRSTNAME_PLACEHOLDER = "Enter first name";
const LASTNAME_PLACEHOLDER = "Enter last name";
const PHONE_PLACEHOLDER = "Enter phone number";
const PASSENGER_LIST_QUERY_KEY = "passengerListQuery";
const LOCATION_PATHNAME_KEY = "locationPathName";

const FLIGHT_CLASS = ["Economy", "Business", "First Class/Premium"];

const ENV_MAPPER = {
  development: "dev",
  staging: "staging",
  test: "test",
  uat: "uat",
  qa: "qa",
  production: "",
  local: "local",
  integration: "intg",
};

const APP_PERMISSION_MAPPER: any = {
  account: "app.account.view",
  workbench: "app.workbench.view",
  admin: "nf.app.platform.view",
  inventory: "app.inventory.view",
  userEdit: "users.self.manage",
  agencyManage: "nf.agencies.manage",
  agencyView: "agencies.view",
  subscriptionManage: "nf.agencies.subscriptions.manage",
  subscriptionView: "agencies.subscriptions.view",
  userManage: "users.manage",
  userView: "users.view",
  transactionManage: "travel.transactions.manage",
  transactionView: "travel.transactions.view",
  transactionSetupManage: "travel.transactions.setup.manage",
  agenciesProfileManage: "agencies.profile.manage",
};

const AUTH_TOKEN =
  getEnv() !== ENV_MAPPER.production ? "authToken" : "ssoToken";

const REFRESH_TOKEN = "refreshToken";
const LI_COMMERCIAL_CODE = "NFLI";

const PUBLISH_SETUP_FORMAT = [
  {
    label: "TRAACS Wave",
    value: 1,
    code: "TRAACS",
  },
  {
    label: "NUTRAACS",
    value: 2,
    code: "NUTRAACS",
  },
];

export {
  USER,
  AUTH_TOKEN,
  TOKEN_TTL,
  IDLE_TIMEOUT_MIN,
  PASSWORD_STRENGTH,
  EMAIL_PLACEHOLDER,
  VERIFICATION_CODE_PLACEHOLDER,
  PASSWORD_PLACEHOLDER,
  FIRSTNAME_PLACEHOLDER,
  LASTNAME_PLACEHOLDER,
  PHONE_PLACEHOLDER,
  ENV_MAPPER,
  APP_PERMISSION_MAPPER,
  REFRESH_TOKEN,
  FLIGHT_CLASS,
  LI_COMMERCIAL_CODE,
  PUBLISH_SETUP_FORMAT,
  PASSENGER_LIST_QUERY_KEY,
  LOCATION_PATHNAME_KEY,
};
