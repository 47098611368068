import AuthStore, { AuthModel } from "store/auth";
import ItemStore, { ItemModel } from "store/item";

export interface StoreModel {
    auth: AuthModel;
    item: ItemModel;
}

const Store: StoreModel = {
    auth: AuthStore,
    item: ItemStore,
};

export default Store;
