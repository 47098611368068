import React from "react";
import Loader from "./Loader";
import { AUTH_TOKEN, REFRESH_TOKEN } from "utils/constants";
import { commitMutation, QueryRenderer as QR } from "react-relay";
import {
  Environment,
  Network,
  RecordSource,
  Store,
  fetchQuery as fQ,
} from "relay-runtime";
import { Cookies } from "react-cookie";
import { isEmpty, setLocalStorage } from "./utils";

const handlerProvider = null;
const cookie = new Cookies();
const source = new RecordSource();
const store = new Store(source);

const network = Network.create(function fetchQuery(
  operation,
  variables,
  cacheConfig,
  uploadables
) {
  // MIGRATE TO: https://github.com/relay-tools/react-relay-network-modern
  const authToken = cookie.get(AUTH_TOKEN);
  const headers = new Headers({ Accept: "application/json" });
  // let headers: any = { "Content-Type": "application/json" };
  if (authToken) {
    headers.append("Authorization", "JWT " + authToken);
  }
  let body: string | FormData;
  if (uploadables) {
    // For file upload
    const formData = new FormData();
    formData.append(
      "operations",
      JSON.stringify({ query: operation.text, variables })
    );
    // const map = {
    //   "0": ["variables.input.file"],
    // };
    // formData.append("0", uploadables[0]);
    const map: Record<string, string[]> = {};
    Object.keys(uploadables).forEach((key, index) => {
      if (Object.prototype.hasOwnProperty.call(uploadables, key)) {
        map[index] = ["variables.input.file"];
      }
    });
    formData.append("map", JSON.stringify(map));
    Object.keys(uploadables).forEach((key, index) => {
      if (Object.prototype.hasOwnProperty.call(uploadables, key)) {
        formData.append(index + "", uploadables[index]);
      }
    });
    body = formData;
  } else {
    headers.append("Content-Type", "application/json");
    body = JSON.stringify({
      query: operation.text,
      variables,
    });
  }

  return fetch(`${process.env.REACT_APP_BASE_URL}/graphql`, {
    // return fetch(`https://api.devqa.nuflights.com/graphql`, {
    method: "POST",
    headers: headers,
    body: body,
  }).then((response) => {
    const json = response.json();
    // console.log(response);
    json
      .then((data: any) => {
        // console.log(data);
        const errors = data?.errors;
        // console.log(errors);
        if (!isEmpty(errors)) {
          const isExpired = errors.some(
            (er: any) => er.message === "Signature has expired"
          );
          if (isExpired) {
            cookie.remove(AUTH_TOKEN, { path: "/" });
            cookie.remove(REFRESH_TOKEN, { path: "/" });
            setLocalStorage("error", "Signature has expired");
            window.location.replace("/");
          }
        }
      })
      .catch((error) => {
        // console.log(error);
      });
    // console.log(json);
    return json;
  });
});

const environment = new Environment({
  handlerProvider,
  network,
  store,
});

function mutateBase(props: any) {
  const onCompleted = (response: any, errors: any) => {
    if (errors && errors.length > 0) {
      var messages: string[] = [];
      errors.forEach((err: any) => {
        messages.push(err.message);
      });
      if (props.onFailure) {
        return props.onFailure(messages);
      } else {
        return alert(messages.join("; "));
      }
    }
    return props.onSuccess(response);
  };
  return commitMutation(environment, {
    mutation: props.mutation,
    variables: props.variables,
    uploadables: props.uploadables,
    onCompleted: onCompleted,
  });
}

function mutate(props: any) {
  const variables = { input: props.input };
  return mutateBase({
    mutation: props.mutation,
    variables: variables,
    uploadables: props.uploadables,
    onSuccess: props.onSuccess,
    onFailure: props.onFailure,
  });
}

function QueryRenderer(_props: any) {
  const component = (
    <QR
      environment={environment}
      render={({ error, props }: { error: any; props: any }) => {
        if (error) {
          return <div> {error.message} </div>;
        }
        if (props && Object.keys(props).length > 0) {
          return _props.onSuccess(props);
        }
        if (_props.isTable) {
          return (
            <tr>
              <td>Loading...</td>
            </tr>
          );
        }
        if (_props.isSelect) {
          return <option>Loading...</option>;
        } else {
          return <Loader />;
        }
      }}
      {..._props}
    />
  );
  return component;
}

function fetchQuery(query: any, variables = {}) {
  return fQ(environment, query, variables);
}

export { QueryRenderer, mutate, fetchQuery, environment, mutateBase };
