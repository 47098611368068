import { toast } from "react-toastify";
import {
  PASSWORD_STRENGTH,
  ENV_MAPPER,
  APP_PERMISSION_MAPPER,
} from "./constants";
import { useEffect } from "react";
import jwt_decode from "jwt-decode";
import CHOICES from "CHOICES";
const jwt = require("jsonwebtoken");

export interface ProxyObjectType {}

const emptyProxyObject: ProxyObjectType = new Proxy(
  {},
  {}
  // {
  //     get: () => {
  //         return emptyProxyObject;
  //     },
  // }
);

const isEmpty = (val: any) => {
  // Stolen From: https://stackoverflow.com/a/28953167
  /*
    test results
    --------------
    [] true, empty array
    {} true, empty object
    null true
    undefined true
    "" true, empty string
    '' true, empty string
    0 false, number
    true false, boolean
    false false, boolean
    Date false
    function false
    */
  if (val === emptyProxyObject) return true;
  if (val === undefined) return true;

  if (
    typeof val == "function" ||
    typeof val == "number" ||
    typeof val == "boolean" ||
    Object.prototype.toString.call(val) === "[object Date]"
  )
    return false;

  if (val == null || val.length === 0)
    // null or 0 length array
    return true;

  if (typeof val == "object") if (Object.keys(val).length === 0) return true;

  return false;
};

export const getValue = (val: any) => {
  return isEmpty(val) ? "-" : val;
};

export interface PasswordStrength {
  length: Boolean;
  numeric: Boolean;
  lowerCase: Boolean;
  upperCase: Boolean;
  specialCharacter: Boolean;
}

export const passwordValidator = (value: any) => {
  const result = {
    length: false,
    lowerCase: false,
    upperCase: false,
    specialCharacter: false,
    numeric: false,
  };
  if (!isEmpty(value)) {
    if (PASSWORD_STRENGTH.MIN_LENGTH <= value.length) {
      result.length = true;
    }
    if (value.match(/[_A-Z]/)) {
      result.upperCase = true;
    }
    if (value.match(/[_a-z]/)) {
      result.lowerCase = true;
    }
    if (value.match(/[_0-9]/)) {
      result.numeric = true;
    }
    if (value.match(/[_\W]/)) {
      result.specialCharacter = true;
    }
  }
  return result;
};

export const notify = (type: string, message: string) => {
  type === "success"
    ? toast.success(message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 10000,
      })
    : type === "warning"
    ? toast.warning(message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 10000,
      })
    : toast.error(message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 10000,
      });
};

const setSessionStorage = (key: string, value: any) => {
  window.sessionStorage.setItem(key, JSON.stringify(value));
};

const getSessionStorage = (key: string) => {
  const value: any = window.sessionStorage.getItem(key);
  return !isEmpty(value) ? JSON.parse(value) : null;
};

const setLocalStorage = (key: string, value: any) => {
  window.localStorage.setItem(key, JSON.stringify(value));
};

const getLocalStorage = (key: string) => {
  const value: any = window.localStorage.getItem(key);
  return JSON.parse(value);
};

const clearLocalStorage = (key: Array<any>) => {
  key.forEach((item) => window.localStorage.removeItem(item));
};

const buildDateToSQLFmt = (ipDate: any) => {
  return (
    new Date(ipDate).getFullYear() +
    "-" +
    ("0" + (new Date(ipDate).getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + new Date(ipDate).getDate()).slice(-2)
  );
};

const buildDateFromSQLFmt = (ipDate: any) => {
  let year = ipDate.split("-")[0];
  let month = ipDate.split("-")[1] - 1;
  let day = ipDate.split("-")[2];
  return new Date(year, month, day);
};

const UseLockBodyScroll = (status: boolean) => {
  // Get original value of body overflow
  if (status) document.body.style.overflow = "hidden";
  else document.body.style.overflow = "auto";
};

const useOutsideClick = (ref: any, callback: any) => {
  const handleClick = (e: any) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
};

const generateRedirectAppUrl = (host: string) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  if (
    baseUrl?.includes("localhost") ||
    baseUrl?.includes("127.0.0.1") ||
    baseUrl?.includes(".dev.")
  ) {
    return `https://${host}.dev.nuflights.com/`;
  }
  if (baseUrl?.includes(".qa.")) {
    return `https://${host}.qa.nuflights.com/`;
  }
  if (baseUrl?.includes(".staging.")) {
    return `https://${host}.staging.nuflights.com/`;
  }
  if (baseUrl?.includes(".intg.")) {
    return `https://${host}.intg.nuflights.com/`;
  }
  return `https://${host}.nuflights.com/`;
};

function getKeyByValue(object: any, value: any) {
  const intValue = parseInt(value);
  return Object.keys(object).find((key) => object[key] === intValue);
}
const getEnv = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  if (baseUrl?.includes("localhost") || baseUrl?.includes("127.0.0.1")) {
    return ENV_MAPPER.local;
  }
  if (baseUrl?.includes(".dev.")) {
    return ENV_MAPPER.development;
  }
  if (baseUrl?.includes(".qa.")) {
    return ENV_MAPPER.qa;
  }
  if (baseUrl?.includes(".staging.")) {
    return ENV_MAPPER.staging;
  }
  if (baseUrl?.includes(".intg.")) {
    return ENV_MAPPER.integration;
  }
  return ENV_MAPPER.production;
};

const verifyAuthToken = (authToken: string, appPermission: string) => {
  // TODO: add Interface for authToken
  let decodedToken: any, scope;
  try {
    decodedToken = jwt_decode(authToken);
    scope = decodedToken?.scope?.split(" ") || [];
  } catch (err) {
    console.error(err);
  }
  return Boolean(scope?.includes(APP_PERMISSION_MAPPER[appPermission]));
};

const nodeEnv = process.env.NODE_ENV;
// TODO: Move to NODE_ENV approach
// const generateRedirectUrl = (host : string) =>
//     nodeEnv === "production"
//         ? `https://${host}.nuflights.com/`
//         : `https://${host}.${ENV_MAPPER[nodeEnv] || "dev"}.nuflights.com/`;

const blockNumericInvalidChar = (e: any) =>
  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

const getCurrencyInShortForm = () => {
  const shortFormCurrencies: any = [];
  for (const [key, value] of Object.entries(CHOICES.CurrencyCode)) {
    if (Number(value) >= 0) {
      shortFormCurrencies.push({ label: key, value: value });
    }
  }
  return shortFormCurrencies;
};

const convertDataUrlToFile = (
  dataUrl: string,
  filename: string
): File | undefined => {
  const arr = dataUrl.split(",");
  if (arr.length < 2) {
    return undefined;
  }
  const mimeArr = arr[0].match(/:(.*?);/);
  if (!mimeArr || mimeArr.length < 2) {
    return undefined;
  }
  const mime = mimeArr[1];
  const buff = Buffer.from(arr[1], "base64");
  return new File([buff], filename, { type: mime });
};

export {
  jwt,
  nodeEnv,
  emptyProxyObject,
  getEnv,
  isEmpty,
  setLocalStorage,
  getLocalStorage,
  clearLocalStorage,
  UseLockBodyScroll,
  buildDateToSQLFmt,
  buildDateFromSQLFmt,
  useOutsideClick,
  generateRedirectAppUrl,
  verifyAuthToken,
  blockNumericInvalidChar,
  getKeyByValue,
  getCurrencyInShortForm,
  convertDataUrlToFile,
  setSessionStorage,
  getSessionStorage,
};
